import { useRouter } from "next/router"
import { useTheme } from "next-themes"

import Button, { VariantType } from "../forms/Button/Button"
import Section from "../Section/Section"
import Arrow from "@/assets/icons/arrow.svg"

import styles from "./styles.module.scss"
import { useHistory } from "@/context/history"

const NotFoundSection = () => {
  const { resolvedTheme } = useTheme()
  const router = useRouter()
  const history = useHistory()

  const handleClickBack = () => {
    if (history?.canGoBack()) {
      router.back()
    } else {
      router.push("/")
    }
  }

  return (
    <Section className={styles.page}>
      <div>
        <div className={styles.page__header}>404</div>
        <h2 className={styles.page__description}>Something’s missing</h2>
        <h1 className={styles.page__title}>
          The page you looking for is not found
        </h1>
        <div className={styles.page__button}>
          <Button
            leftIcon={<Arrow />}
            variant={resolvedTheme as VariantType}
            onClick={handleClickBack}
          >
            Go Back
          </Button>
        </div>
      </div>
    </Section>
  )
}

export default NotFoundSection
