import getSettings from "@/services/settings"

import NotFoundSection from "@/components/NotFoundSection/NotFoundSection"

const Custom404 = () => {
  return <NotFoundSection />
}

export default Custom404

export async function getStaticProps(context: any) {
  const settings = await getSettings()

  return {
    props: { settings },
  }
}
